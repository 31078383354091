import axios from "@/utils/axios";
import { userInfo , mylist , recordCancel , addRecor , organizations , user_meal , service_commission_user , detaile_list , last_info , subscribe_info , assess_list , assess_add , my_list , time_slot , diaoyan } from "@/api";
import { Notify } from 'vant';

let my = {
    namespaced: true,
    state: {
        info:false,
        recordList:[],
        organizations:[],
        mealData:[],
        commission_user:false,
        detaile_list:false,
        loading:false,
        last_info:null,
        subscribe_info:null,
        assess_list:[],
        my_list:[],
        time_slot:[],
        diaoyan:false
    },
    mutations: {            //同步方法
        //用户信息
        infoAxios(state){
            axios(`/userapi${userInfo}`,{
                event:1
            }).then((data)=>{
                if(data.code==1){
                    state.info = data.data;
                }
            });
        },
        //预约列表
        recordAxios(state){
            axios(`/kfApi${mylist}`,{}).then((data)=>{
                if(data.code==1){
                    state.recordList = data.data;
                }
            });
        },
        //取消预约
        recordCancelAxios(state,obj){
            axios(`/kfApi${recordCancel}`,{
                id:obj.id
            }).then((data)=>{
                if(data.code==1){
                    Notify({ type: 'success', message: '预约已取消！' });
                    if(obj.scene=='info'){
                        this.dispatch('my/subscribeInfoMain',{id:obj.id});
                    }else{
                        this.dispatch('my/recordMain');
                    }
                }
            });
        },
        //新增预约
        addRecorAxios(state,obj){
            axios(`/kfApi${addRecor}`,obj.data,'POST').then((data)=>{
                if(data.code==1){
                    Notify({ type: 'success', message: '预约成功！' });
                    obj._this.$router.push({
                        name:'record',
                    });
                }else{
                    Notify({ type: 'danger', message: data.msg });
                }
            });
        },
        //机构列表
        organizationsAxios(state,obj){
            axios(`/kfApi${organizations}`,obj).then((data)=>{
                if(data.code==1){
                    let organizationsPicker = [];
                    data.data.map((organi)=>{
                        organizationsPicker.push(organi.name);
                    })
                    state.organizationsPicker = organizationsPicker;
                    state.organizations = data.data;
                }
            });
        },
        //套餐列表
        userMealAxios(state,obj){
            axios(`/kfApi${user_meal}`,obj).then((data)=>{
                if(data.code==1){
                    state.mealData = data.data
                }
            });
        },

        //身份验证
        userIdAxios(state,obj){
            axios(`/kfApi${service_commission_user}`,obj).then((data)=>{
                if(data.code==1){
                    state.commission_user = data.data
                }
            });
        },

        //明细列表
        detaileListAxios(state,obj){
            state.loading = true;
            axios(`/kfApi${detaile_list}`,obj,'POST').then((data)=>{
                if(data.code==1){
                    state.detaile_list = data.data;
                    state.loading = false;
                }
            });
        },

        //上一次预约记录
        lastInfoAxios(state,obj){
            axios(`/kfApi${last_info}`,obj).then((data)=>{
                if(data.code==1){
                    state.last_info = data.data;
                    state.subscribe_info=null;
                }
            });
        },
        //单条预约详情
        subscribeInfoAxios(state,obj){
            axios(`/kfApi${subscribe_info}`,obj).then((data)=>{
                if(data.code==1){
                    state.subscribe_info = data.data;
                    state.last_info=null;
                }else{
                    Notify({ type: 'danger', message: data.msg });
                }
            });
        },
        //评价列表
        assessListAxios(state,obj){
            axios(`/kfApi${assess_list}`,obj).then((data)=>{
                if(data.code==1){
                    state.assess_list = data.data;
                }
            });
        },
        //去评价
        assessAddAxios(state,obj){
            axios(`/kfApi${assess_add}`,obj.data ,'POST').then((data)=>{
                if(data.code==1){
                    Notify({ type: 'success', message: '评价成功！' });
                    obj._this.$router.back('-1');
                }
            });
        },
        //会员卡
        myListAxios(state){
            axios(`/kfApi${my_list}`,{}).then((data)=>{
                if(data.code==1){
                    state.my_list = data.data;
                }
            });
        },
        //预约时间段
        timeSlotAxios(state,obj){
            axios(`/kfApi${time_slot}`,obj,'POST').then((data)=>{
                if(data.code==1){
                    state.time_slot = data.data;
                }
            });
        },
        //活动报名
        diaoyanAxios(state,obj){
            axios(`/kfApi${diaoyan}`,obj,'POST').then((data)=>{
                if(data.code==1){
                    state.diaoyan = true;
                }
            });
        }
    },
    actions: {              //异步方法
        axiosMain({commit}){
            commit('infoAxios')
        },
        recordMain({commit}){
            commit('recordAxios')
        },
        recordCancelMain({commit},obj){
            commit('recordCancelAxios',obj)
        },
        addRecorMain({commit},obj){
            commit('addRecorAxios',obj)
        },
        organizationsMain({commit},obj){
            commit('organizationsAxios',obj)
        },
        userMealMain({commit},obj){
            commit('userMealAxios',obj)
        },
        userIdMain({commit},obj){
            commit('userIdAxios',obj)
        },
        detaileListMain({commit},obj){
            commit('detaileListAxios',obj)
        },
        lastInfoMain({commit},obj){
            commit('lastInfoAxios',obj)
        },
        subscribeInfoMain({commit},obj){
            commit('subscribeInfoAxios',obj)
        },
        assessListMain({commit},obj){
            commit('assessListAxios',obj)
        },
        assessAddMain({commit},obj){
            commit('assessAddAxios',obj)
        },
        myListMain({commit},obj){
            commit('myListAxios',obj)
        },
        timeSlotMain({commit},obj){
            commit('timeSlotAxios',obj)
        },
        diaoyanMain({commit},obj){
            commit('diaoyanAxios',obj)
        },
    },
    getters: {},
}

export default my;
