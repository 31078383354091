import axios from "@/utils/axios";
import { submit , question_add , question_add_notoken , user_info , setReport , setsetReportToken , lastInfo } from "@/api";
import { ages , RandomString } from "@/utils";
import { Toast } from 'vant';
let step = {
    namespaced: true,
    state: {
        showData:[
        {
            sayer:'me',     //me:小助手，user:用户
            type:'talk',    // talk:只有一句话，info:填写信息，radio:单选，checkbox:多选
            data:'你好，欢迎使用小助手问诊功能'
        },{
            sayer:'me',
            type:'info',
            data:[{
                name:'性别',
                type:'radio',
                data:['男','女'],
                set:'sex',
                question_id:1
            },{
                name:'出生日期',
                type:'date',
                set:'date',
                question_id:2
            },{
                name:'身高(cm)',
                type:'height',
                set:'height',
                question_id:3
            },{
                name:'体重(kg)',
                type:'weight',
                set:'weight',
                question_id:4
            }]
        }
        ],                  // 展示数组，用于前端展示
        answer:'[]',         // 存储答案
        answerRefer:[],
        checkbox:[],         // 多选数组
        checkboxIndex:-1,    // 多选数组的父级下标
        sonIndex:-1,         // 多选数组的子级下标
        info:{
            sex:1,
            date:'',
            height:'',
            weight:'',
            doc:'',
            user_name:'',
            carte_vital:'',
            id_code:''
        },
        showIndex:-1,
        questionBool:false,
        timeBool:false,
        noClick:1,
        unique_token:RandomString(16),   //随机数,
        loading:false,
        doctor:false,
        man:false,
        nowu:true
    },

    mutations: {  //同步方法
        usualFun(state){
            state.man = true;
            state.doctor = {};
        },

        //获取上一个基础信息
        lastAxios(state){
            let user_id = {};
            let assess = JSON.parse(sessionStorage.getItem('assess'));
            if(assess){
                user_id = {
                    user_id:assess.user_id
                }
            }
            axios (`/api${lastInfo}`,user_id).then((data)=>{
                let answer=[];
                try{
                    answer = JSON.parse(data.data.answer);
                }catch(err) {
                    console.log(err);
                }
                answer.map((ans)=>{
                    if(ans.id==-1){
                        state.info.sex = ans.answer[0]=='A'?1:2;
                    }
                    if(ans.id==-2){
                        state.info.date = ans.answer[0];
                    }
                    if(ans.id==-3){
                        state.info.height = ans.answer[0];
                    }
                    if(ans.id==-4){
                        state.info.weight = ans.answer[0];
                    }
                });
                state.info.date=data.data.birthday;
                state.info.height=data.data.height;
                state.info.weight=data.data.weight;
                state.info.sex=data.data.sex?data.data.sex:1;
            })
        },

        accountAxios(state){
            axios(`/userapi${user_info}`).then((data)=>{
                if(data.code==1){
                    state.man = true;
                    state.doctor=data.data.doctor;
                }else if(data.code==4003){
                    state.man = true;
                    Toast.fail('您还不是医生');
                }else{
                    state.man = true;
                    Toast.fail(data.msg);
                }
            });
        },
        //根据父问题id获取他的所有子问题
        matterAxios(state,params){
            let answerArray = JSON.parse(state.answer),
            answerTip = [];
            answerArray.map((item)=>{
                if(Array.isArray(item)){
                    item.map((it)=>{
                        answerTip.push(it.id);
                    })
                }else{
                    answerTip.push(item.id);
                }
            })
            let flow = sessionStorage.getItem('flow');
            let flow_equipment = "";

            if(flow){
                flow_equipment = JSON.parse(flow).flow_info.flow_equipment;
            }

            axios(`/api${flow_equipment==7?question_add_notoken:question_add}`,{        //有无token
                question_id:params.id,
                sex:state.info.sex,
                answer:answerTip.length>0?Array.from(new Set(answerTip)).join(','):0
            }).then((data)=>{
                if(data.code==1){
                    if(params.id==251){         //基础信息验证
                        if(!params.bool){
                            if(!state.info.date){
                                Toast.fail('请选择您的出生日期！');
                                return;
                            }
                            if(!state.info.height){
                                Toast.fail('请输入您的身高！');
                                return;
                            }
                            if(!state.info.weight){
                                Toast.fail('请输入您的体重！');
                                return;
                            }
                        }
                        state.checkbox = data.data.son;
                        state.checkboxIndex=0;
                        state.showIndex++;
                        state.answerRefer.push({
                            id:data.data.question_id
                        });
                        state.noClick = state.noClick+1;
                        state.showData.push({
                            sayer:'me',
                            type:data.data.question_type==1?'radio':'checkbox',
                            data:data.data,
                            showIndex:state.showIndex,
                            nameShow:true
                        })
                    }else if(!data.data.son){   
                        if(state.checkbox[state.checkboxIndex].son){    //多选未尽
                            state.sonIndex++;
                            if(state.checkbox[state.checkboxIndex].son.length-1>=state.sonIndex){
                                this.dispatch('prescribeQuestion/matterData',{
                                    id:state.checkbox[state.checkboxIndex].son[state.sonIndex].id
                                });
                            }else{
                                state.sonIndex=-1;
                                let answerArray = state.answerRefer,
                                answerTip = [];
                                answerArray.map((item)=>{
                                    if(Array.isArray(item)){
                                        item.map((it)=>{
                                            answerTip.push(it.id);
                                        })
                                    }else{
                                        answerTip.push(item.id);
                                    }
                                })
                                if(answerTip.join(',').indexOf('1,400')>=0 && state.nowu){
                                    state.checkboxIndex=4;
                                    state.nowu = false;
                                    this.dispatch('prescribeQuestion/matterData',{
                                        id:state.checkbox[state.checkboxIndex].id
                                    });
                                }else{
                                    // if(state.showIndex==1&&state.checkboxIndex==0){
                                    //     state.checkboxIndex++;
                                    //     this.dispatch('prescribeQuestion/matterData',{
                                    //         id:state.checkbox[state.checkboxIndex].id,
                                    //         nameShow:true
                                    //     });
                                    // }else{
                                        if(state.checkboxIndex==0&&answerTip[1]==97){
                                            state.checkboxIndex=1;
                                            this.dispatch('prescribeQuestion/matterData',{
                                                id:state.checkbox[state.checkboxIndex].id,
                                                nameShow:true
                                            });
                                        }else{
                                            state.noClick = state.noClick+1;
                                            state.questionBool = true;
                                        }                            
                                    // }
                                }
                            }
                        }else{      //多选走完了
                            state.sonIndex=-1;
                            let answerArray = state.answerRefer,
                            answerTip = [];
                            answerArray.map((item)=>{
                                if(Array.isArray(item)){
                                    item.map((it)=>{
                                        answerTip.push(it.id);
                                    })
                                }else{
                                    answerTip.push(item.id);
                                }
                            })
                            
                            answerTip = Array.from(new Set(answerTip));
                            if(answerTip.join(',').indexOf('1,400')>=0 && state.nowu){
                                state.checkboxIndex=4;
                                state.nowu = false;
                                this.dispatch('prescribeQuestion/matterData',{
                                    id:state.checkbox[state.checkboxIndex].id
                                });
                                // state.noClick = state.noClick+1;
                                // state.questionBool = true;
                            }else{
                                if(state.checkboxIndex==0&&answerTip[1]==97){    //下一个大项
                                    state.checkboxIndex++;
                                    this.dispatch('prescribeQuestion/matterData',{
                                        id:state.checkbox[state.checkboxIndex].id,
                                        nameShow:true
                                    });
                                }else{      
                                    state.noClick = state.noClick+1;
                                    state.questionBool = true;
                                }
                            }
                        }
                    }else{
                        state.showIndex++;
                        if(!params.fox){
                            let slop = [];
                            let answer = JSON.parse(state.answer);
                            if(Array.isArray(answer[answer.length-1])){
                                answer[answer.length-1].map((item)=>{
                                    slop.push(item.question);
                                });
                            }else{
                                slop.push(answer[answer.length-1].matter.question);
                            }
                            state.noClick = state.noClick+1;
                            state.showData.push({
                                sayer:'user',
                                type:'talk',
                                data:slop
                            });
                        }
                        state.answerRefer.push({
                            id:data.data.question_id
                        });
                        state.noClick = state.noClick+1;
                        state.showData.push({
                            sayer:'me',
                            type:data.data.question_type==1?'radio':'checkbox',
                            data:data.data,
                            showIndex:state.showIndex,
                            nameShow:params.nameShow
                        })
                    }
                    setTimeout(() => {
                        //滚动条到最底部
                        window.scrollTo(0,document.body.scrollHeight);
                    },500);
                }
            });
        },
        //检测提交
        submitAxios(state,params){
            let answerArray = state.answerRefer,
            answerTip = [];
            answerArray.map((item)=>{
                if(Array.isArray(item)){
                    item.map((it)=>{
                        answerTip.push(it.id);
                    })
                }else{
                    answerTip.push(item.id);
                }
            })

            let face_id,tongue_id;
            if(!params._this.$route.params.id){
                face_id = sessionStorage.getItem('faceID');
                tongue_id = sessionStorage.getItem('tongueID');
                if(!face_id){
                    Toast.fail('面诊未完成，请重新检测！');
                    return;
                }

                let flow = sessionStorage.getItem('flow');
                let flow_step = "";
                if(flow){
                    flow_step = JSON.parse(flow).flow_info.flow_step;
                }
                if(flow_step!==6){
                    if(!tongue_id){
                        Toast.fail('舌诊未完成，请重新检测！');
                        return;
                    }
                }
            }

            let equipment_code='',sys_company_id='';
            let check_type = 1,user_id=0,yao_account_id=0,server_type=0;
            let assess = JSON.parse(sessionStorage.getItem('assess'));
            if(assess){
                server_type = assess.server_type;
                user_id = assess.user_id;
                yao_account_id = assess.yao_account_id;
                sys_company_id = assess.sys_company_id;
                if(user_id>0){
                    check_type=2
                }
            }

             //如果是设备
             let equipment = JSON.parse(sessionStorage.getItem('equipment'));
             let device = sessionStorage.getItem('device');
             if(equipment && (device=='android' || device=='windows')){
                equipment_code = equipment.equipment_code;
                sys_company_id = equipment.sys_company_id;
            }
            
            let apiAxios;
            if(params._this.$route.params.id){
                apiAxios = axios(`/api${submit}`,{
                    prescribe_answer : Array.from(new Set(answerTip)).join(','),
                    description:state.info.doc,
                    report_token:params._this.$route.params.id
                },'POST');
            }else{
                console.log(sessionStorage.token,1);
                let question_api = sessionStorage.token?setsetReportToken:setReport;
                console.log(question_api.token,2);
                let content = state.info.date.split(' ')[0].split('-');
                
                apiAxios = axios(`/api${question_api}`,{
                    sex:state.info.sex,
                    age:ages(`${content[0]}-${content[1]}-${content[2]}`),
                    birthday:`${content[0]}-${content[1]}-${content[2]} ${state.info.date.split(' ')[1]}`,
                    height:Number(state.info.height),
                    weight:Number(state.info.weight),
                    face_id:face_id,
                    tongue_id:tongue_id,
                    answer:Array.from(new Set(answerTip)).join(','),
                    unique_token:state.unique_token,
                    equipment_code:equipment_code,
                    sys_company_id:Number(sys_company_id),
                    user_name:state.info.user_name,
                    id_code:state.info.id_code,
                    carte_vital:state.info.carte_vital,
                    check_type:Number(check_type),
                    user_id:Number(user_id),
                    yao_account_id:Number(yao_account_id),
                    server_type:Number(server_type),
                    prescribe_answer : Array.from(new Set(answerTip)).join(','),
                    prescribe_description:state.info.doc
                },'POST');
            }

            state.loading = true;
            apiAxios.then((data)=>{
                if(data.code==1){
                    state.loading = false;
                    if(params._this.$route.params.id){
                        params._this.$router.push({
                            name:'doctorReport',params: {
                                id:data.data.token
                            }
                        });
                    }else{
                        if(check_type==1){
                            params._this.$router.push({
                                name:'report',params: {
                                    id:data.data.token
                                }
                            });
                        }else{
                            params._this.$router.push({
                                name:'doctorReport',params: {
                                    id:data.data.token
                                }
                            });
                        }
                    }
                }else{
                    Toast.fail(data.msg);
                }
            });
            
        },
        //改变数值
        change(state,data){
            state.info[data.key] = data.value;
        },
        //改变时间
        timeChange(state){
            state.timeBool = !state.timeBool;
        },
        //时间
        timeSubmit(state,value){
            let date = new Date(value);
            let y = date.getFullYear();
            let m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let minute = date.getMinutes();
            minute = minute < 10 ? ('0' + minute) : minute;
            state.info.date = `${y}-${m}-${d} ${h}:${minute}`;
            state.timeBool = !state.timeBool;
        },
        //添加答案
        answerChange(state,data){
            if(data.sIndex==state.noClick){
                let answer = JSON.parse(state.answer);
                let answerRefer = state.answerRefer;
                answer[data.index]=data;
                answerRefer[data.index+data.index+1]=data;
                state.answer = JSON.stringify(answer);
                state.answerRefer = answerRefer;
            }
        },
        //多选
        answerMultiple(state,data){
            if(data.sIndex==state.noClick){
                let answer = JSON.parse(state.answer);
                let answerRefer = state.answerRefer;
                if(data.id=='246'||data.id=='60'){
                    answer[data.index] = [data];
                }else{
                    if(answer[data.index]){
                        answer[data.index].map((item,index)=>{
                            if(item.id=='246'||item.id=='60'){
                                answer[data.index].splice(index,1);
                            }
                        })
                        //去重
                        let answerBool = true;
                        let answerIndex;
                        answer[data.index].map((item,index)=>{
                            if(data.id==item.id){
                                answerBool = false;
                                answerIndex = index;
                            }
                        });
                        if(!answerBool){
                            answer[data.index].splice(answerIndex,1);
                            answerRefer[data.index+data.index+1].splice(answerIndex,1);
                        }else{
                            answer[data.index].push(data);
                            answerRefer[data.index+data.index+1].push(data);
                        }
                    }else{
                        answer[data.index]=[data];
                        answerRefer[data.index+data.index+1]=[data];
                    }
                }
                state.answer = JSON.stringify(answer);
            }
        },
        //多选 -> 确定
        multipleAgent(state,data){
            if(data.data.length>0){
                state.sonIndex++
                if(state.checkbox[state.checkboxIndex].son){
                    data.data.map((item,index)=>{
                        state.checkbox[state.checkboxIndex].son.splice(state.sonIndex+index,0,item)
                    })
                }else{
                    state.checkbox[state.checkboxIndex].son = data.data
                }
            }
            if(state.checkbox[state.checkboxIndex].son){
                this.dispatch('prescribeQuestion/matterData',{
                    id:state.checkbox[state.checkboxIndex].son[state.sonIndex].id
                });
            }else{
                this.dispatch('prescribeQuestion/matterData',{
                    id:state.checkbox[state.checkboxIndex].id
                });
            }
        }
    },
    actions: {    //异步方法
        matterData({commit},params){
            commit('matterAxios',params)
        },
        submitData({commit},params){
            commit('submitAxios',params)
        },
        accountData({commit}){
            commit('accountAxios')
        },
        lastData({commit}){
            commit('lastAxios')
        }
    },
    getters: {},
}

export default step;