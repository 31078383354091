<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
import { getCookie , getDomain } from '@/utils';
export default {
  name: 'App',
  mounted(){
     this.cookieFun();
     this.environment();
  },
  methods:{
      //如果cookie里面有token，那么就存到本地使用
      cookieFun(){
          let appid = getCookie('appid');
          let domain = getDomain();
          //如果appid相同（微信项目），如果是设备项目
          if(appid==domain||domain=='localhost'||domain=="device"){   //localhost是为了调试方便
            let token = getCookie('token');
            //把token存下来
            if(token && sessionStorage.isToken && JSON.parse(sessionStorage.isToken)){
                sessionStorage.setItem("isToken",false);
                sessionStorage.setItem("token",token);
            }
          }else{
            sessionStorage.removeItem('token');
          }
          
      },
      //判断环境
      environment(){
          if(this.isWeixn()){
            sessionStorage.setItem("device", "wx");           //添加微信标识
            //是否是安卓
            let ua = navigator.userAgent;
            if(/android/i.test(ua)){
              this.oldFun();
            }
          } else if (this.isWindows()){
            sessionStorage.setItem("device", "windows");      //添加windows标识
          } else if (this.isAndroid()){
            sessionStorage.setItem("device", "android");      //添加安卓标识
          }
      },
      //判断是否是微信
      isWeixn(){
          var ua = navigator.userAgent.toLowerCase();
          if(ua.match(/MicroMessenger/i)=="micromessenger") {
              return true;
          } else {
              return false;
          }
      },
      //判断是否是安卓设备
      isAndroid(){
          if(window["ReactNativeWebView"]){
              return true;
          }else{
              return false;
          }
      },
      //判断是否是win设备
      isWindows(){
          if (window.isElectron) {
              return true;
          }else{
              return false;
          }
      },
      //老人机
      oldFun(){
        let WeixinJSBridge = window.WeixinJSBridge;
        let handleFontSize = window.handleFontSize;
        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
            WeixinJSBridge.on('menu:setfont', function() {
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize' : 0 });
            });
        } else {
            if (document.addEventListener) {
                document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
            } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", handleFontSize);
                document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
            }
        }
      }
  }
}
</script>
