import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes:[
        //设备首页
        { path:'/' , name:'device' , component: resolve => require(['./views/device'],resolve) },
        //激活码
        { path:'/code' , name:'code' , component: resolve => require(['./views/code'],resolve) },
        //评估系统的介绍
        { path:'/confirmAssess' , name:'confirmAssess' , component: resolve => require(['./views/confirmAssess'],resolve) },
        { path:'/confirmAssess/:id' , name:'confirmAssess_id' , component: resolve => require(['./views/confirmAssess'],resolve) },
        //面诊介绍
        { path:'/confirmToface' , name:'confirmToface' , component: resolve => require(['./views/confirmToface'],resolve) },
        //舌诊介绍
        { path:'/confirmTongue' , name:'confirmTongue' , component: resolve => require(['./views/confirmTongue'],resolve) },
        //问诊介绍
        { path:'/confirmask' , name:'confirmAsk' , component: resolve => require(['./views/confirmAsk'],resolve) },
        //面诊
        { path:'/step1' , name:'step1' , component: resolve => require(['./views/step1'],resolve) },
        //舌诊
        { path:'/step2' , name:'step2' , component: resolve => require(['./views/step2'],resolve) },
        //用户中心
        { path:'/user' , name:'user' , component: resolve => require(['./views/user'],resolve) },
        //上传医师资格证
        { path:'/upload' , name:'upload' , component: resolve => require(['./views/upload'],resolve) },
        //填写名字和性别
        { path:'/becomeDoctor' , name:'setUserInfo' , component: resolve => require(['./views/becomeDoctor'],resolve) },
        //扫码添加用户
        { path:'/addUserCode' , name:'addUserCode' , component: resolve => require(['./views/addUserCode'],resolve) },
        //修改用户信息
        { path:'/profile' , name:'profile' , component: resolve => require(['./views/profile'],resolve) },
        //设备问诊
        { path:'/question' , name:'question' , component: resolve => require(['./views/question'],resolve) },
        //设备问诊 - 皮肤
        { path:'/bianz' , name:'bianz' , component: resolve => require(['./views/question/bianz'],resolve) },
        //开方问诊
        { path:'/prescribeQuestion' , name:'prescribeQuestion' , component: resolve => require(['./views/prescribeQuestion'],resolve) },
        { path:'/prescribeQuestion/:id' , name:'prescribeQuestion_id' , component: resolve => require(['./views/prescribeQuestion'],resolve) },
        //报告详情
        { path:'/report/:id' , name:'report' , component: resolve => require(['./views/report/bianz'],resolve) },
        //养生列表
        { path:'/reportList' , name:'reportList' , component: resolve => require(['./views/reportList'],resolve) },
        { path:'/reportList/:id' , name:'reportList_id' , component: resolve => require(['./views/reportList'],resolve) },
        //医生端
        { path:'/doctorReport/:id' , name:'doctorReport' , component: resolve => require(['./views/doctorReport'],resolve) },
        //报告列表
        { path:'/list/:id' , name:'list' , component: resolve => require(['./views/list'],resolve) },
        //添加用户
        { path:'/addUser/:id' , name:'addUser' , component: resolve => require(['./views/addUser'],resolve) },
        //提前注册
        { path:'/login' , name:'login' , component: resolve => require(['./views/login'],resolve) },
        /**
         * 活动页面
         */
        //红包
        { path:'/redPacket/:id' , name:'redPacket' , component: resolve => require(['./views/activity/redPacket'],resolve) },
        //测试页面
        { path:'/home' , name:'home' , component: resolve => require(['./views/home'],resolve) },
        // 理疗预约界面
        { path:'/subscribe' , name:'subscribe',  component: resolve => require(['./views/subscribe'],resolve) },
        { path:'/subscribe/:id' , name:'subscribe',  component: resolve => require(['./views/subscribe'],resolve) },
        // 理疗预约记录
        { path:'/record' , name:'record',  component: resolve => require(['./views/record'],resolve) },
        // 理疗 - 我的
        { path:'/my' , name:'my',  component: resolve => require(['./views/my'],resolve) },
        // 理疗 - 我的套餐
        { path:'/myMeal/:id' , name:'myMeal',  component: resolve => require(['./views/myMeal'],resolve) },
        // 理疗 - 业绩提成
        { path:'/profit/:id' , name:'profit',  component: resolve => require(['./views/profit'],resolve) },
        // 评价列表
        { path:'/assess' , name:'assess',  component: resolve => require(['./views/assess'],resolve) },
        // 去评价
        { path:'/assessInfo/:id' , name:'assessInfo',  component: resolve => require(['./views/assessInfo'],resolve) },
        // 我的会员卡
        { path:'/card' , name:'card',  component: resolve => require(['./views/card'],resolve) },
        //调查问卷
        { path:'/questionnaire' , name:'questionnaire',  component: resolve => require(['./views/questionnaire'],resolve) },
        //404
        { path:'/*' , name:'404',  component: resolve => require(['./views/404'],resolve) }
    ]
})
