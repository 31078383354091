import axios from "@/utils/axios";
import { getReport , sendTemplate , getReportToken , userInfo , get_modules } from "@/api";
import { Notify , Toast } from 'vant';
import { audioPaly , pageFun } from "@/utils";
import { reportover } from "@/utils/audio";
const echarts = require('echarts/lib/echarts');
//折
require('echarts/lib/chart/gauge');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');
//饼
require('echarts/lib/component/title');
require('echarts/lib/component/tooltip');
require('echarts/lib/component/legend');
require('echarts/lib/chart/pie');

// //模块选择
// function module(data,page,add,info,women){
//     let module_y=[
//         [1,3],
//         [2],
//         [5],
//         [6],
//         [7],
//         [4],
//         [8]
//     ];
//     let new_page = 0;
//     if(add>0){
//         for(let i=page-1,y=module_y.length;i<y;i++){
//             let bool = false;
//             for(let s=0,o=module_y[i].length;s<o;s++){
//                 if(data.indexOf(module_y[i][s])>-1){
//                     if(i==4){
//                         if(info){
//                             bool = true;
//                             break;
//                         }
//                     } else if(i==6){
//                         if(women){
//                             bool = true;
//                             break;
//                         }
//                     } else{
//                         bool = true;
//                         break;
//                     }
//                 }
//             }
//             if(bool){
//                 new_page=i+1;
//                 break;
//             }
//         }
//         new_page = new_page==0?8:new_page;
//     }else{
//         for(let i=page-1;0<=i;i--){
//             let bool = false;
//             for(let s=0,o=module_y[i].length;s<o;s++){
//                 if(data.indexOf(module_y[i][s])>-1){
//                     if(i==4){
//                         if(info){
//                             bool = true;
//                             break;
//                         }
//                     }else if(i==6){
//                         if(women){
//                             bool = true;
//                             break;
//                         }
//                     } else{
//                         bool = true;
//                         break;
//                     }
//                 }
//             }
//             if(bool){
//                 new_page=i+1;
//                 break;
//             }
//         }
//         new_page = new_page==0?1:new_page;
//     }
//     return new_page;
// }

let report = {
    namespaced: true,
    state: {
        info:false,
        resultsArr:[],
        user:{},
        module:{
            renovate:true,
            data:[]
        },
        loading:false,
        login:false,
        send:false,
        prescribe:false,
        doctor:false,
        page:0,
        module_list:[],
        module_name:[],
        template_id:0,
        history:false,
        loginG:false
    },
    mutations: {            //同步方法
        //清空数据
        closeFun(state){
            state.info = false;
            state.resultsArr = [];
            state.module = { renovate:true,data:[] }
            state.loading = false;
            state.login = false;
            state.send = false;
            state.page = 0;
            state.user = {};
            state.history = false;
            state.loginG = false;
        },
        //改变数值
        changeFun(state,data){
            state[data.key] = data.value;
        },
        //一级目录
        change(state,params){
            let module = state.module.data;
            for(let item in module[params.index].description_list){
                module[params.index].description_list[item].active = false;
            }
            module[params.index].description_list[params.index1].active = true;
            state.module = {
                renovate:!state.module.renovate,
                data:module
            }
        },
        //二级目录
        changeTwo(state,params){
            let module = state.module.data;
            for(let item in module[params.index].description_list[params.index1].list){
                module[params.index].description_list[params.index1].list[item].active = false;
            }
            module[params.index].description_list[params.index1].list[params.index2].active=true;
            state.module = {
                renovate:!state.module.renovate,
                data:module
            }
        },

        //翻页
        // pageChange(state,data){
        //     let info = state.info;
        //     let _this = this;
        //     if(data.page==5){
        //         if(info.index_list){
        //             state.page = data.page;
        //                 setTimeout(function(){
        //                     let w = document.body.clientWidth;
        //                     let h = document.body.clientHeight;
        //                     if(w>h){
        //                         // _this.dispatch('report/broken',{
        //                         //     dom:'y-broken',
        //                         //     date:info.index_list.date,
        //                         //     data:info.index_list.score,
        //                         //     max:100
        //                         // });
        //                         _this.dispatch('report/broken',{
        //                             dom:'y-weight',
        //                             date:info.index_list.date,
        //                             data:info.index_list.bmi,
        //                             max:40
        //                         });
        //                     }else{
        //                         // _this.dispatch('report/broken',{
        //                         //     dom:'broken',
        //                         //     date:info.index_list.date,
        //                         //     data:info.index_list.score,
        //                         //     max:100
        //                         // });
        //                         _this.dispatch('report/broken',{
        //                             dom:'weight',
        //                             date:info.index_list.date,
        //                             data:info.index_list.bmi,
        //                             max:40
        //                         });
        //                     }
        //                 },1);
        //         }else{
        //             if(data.add>0){
        //                 state.page = data.page+1;
        //             }else{
        //                 state.page = data.page-1;
        //             }
        //         }
        //     }else if(data.page==8 && (state.info<18 || info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡') && !data.bool ){
        //         if(data.add>0){
        //             state.page = data.page+2;
        //         }else{
        //             state.page = data.page;
        //         }
        //     }else if(data.page==9 && (state.info<18 || info.sick_text_array.length==1&&info.sick_text_array[0]=='平衡' && !data.bool) ){
        //         if(data.add<0){
        //             if(data.page-2==7 && !info.women){
        //                 state.page = data.page-3;
        //             }else{
        //                 state.page = data.page-2;
        //             }
        //         }else{
        //             state.page = data.page;
        //         }
        //     }else{
        //         //if(data.page==7 && data.bool && !info.women){      
        //         if(data.page==7){ 
        //             if(data.add>0){
        //                 state.page = data.page+1;
        //             }else{
        //                 state.page = data.page-1;
        //             }
        //         }else{
        //             state.page = data.page;
        //         }
        //     }
        //     if(!data.bool){
        //         let w = document.body.clientWidth;
        //         let h = document.body.clientHeight;
        //         if(w>h){
        //             if( data.page==3 ){
        //                 setTimeout(function(){
        //                     _this.dispatch('report/meter','mainwidth');
        //                 },1);
        //             }
        //         }
        //     }
        //     let bg = document.getElementsByClassName('new-bg');
        //     bg[0].scrollTop = 0;
        //     if(data.page<8 && data.report_section){
        //         let new_page = module(JSON.parse(data.report_section),data.page,data.add,info.index_list,data.bool && !info.women); //女性产后保健
        //         state.page = new_page;
        //     }
        // },

        //翻页
        pageChange(state,data){
            let info = state.info;
            let _this = this;
            let w = document.body.clientWidth;
            let h = document.body.clientHeight;

            if(data.page==3){
                if(info.index_list){
                    setTimeout(function(){
                        if(w>h){
                            _this.dispatch('report/broken',{
                                dom:'y-weight',
                                date:info.index_list.date,
                                data:info.index_list.bmi,
                                max:40
                            });
                        }else{
                            _this.dispatch('report/broken',{
                                dom:'weight',
                                date:info.index_list.date,
                                data:info.index_list.bmi,
                                max:40
                            });
                        }
                    },1)
                }
            }else if(data.page==1){
                setTimeout(function(){
                    if(w>h){
                        _this.dispatch('report/meter','mainwidth');
                    }else{
                        _this.dispatch('report/meter','main');
                    }
                },1)
            }
            
            function page1Fun(page){
                if(!pageFun(page,info)){
                    page1Fun(data.add>0?page+1:page-1);
                }else{
                    console.log(page,'确定页数');
                    state.page = page;
                }
            }

            page1Fun(data.page);
        },

        //仪表盘
        Meter(state,params){
            var chartDom = document.getElementById(params);
            echarts.dispose(chartDom);
            var myChart = echarts.init(chartDom);
            var option
            option = {
                series: [{
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 100,
                    splitNumber: 0,
                    axisLine: {
                        lineStyle: {
                            width: 6,
                            color: [
                                [0.5, '#F36440'],
                                [0.65, '#F89143'],
                                [0.8, '#ECDB44'],
                                [0.9, '#5CC7BC'],
                                [1, '#83D186']
                            ]
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                            color: 'auto',
                            width: 5
                        }
                    },
                    axisLabel: {
                        color: '#464646',
                        fontSize: 15,
                        distance: -60,
                        formatter: function (value) {
                            return value?value:''
                        }
                    },
                    detail: {
                        fontSize: 16,
                        offsetCenter: [0, '40%'],
                        valueAnimation: false,
                        formatter: function (value) {
                            let score = Math.round(value);
                            let score_text = '';
                            if(0<score && score<=50){
                                score_text = '可能患有疾病，建议就医检查';
                            }
                            if(50<score && score<=65 ){
                                score_text= "身体极不平衡，建议马上调理";
                            }
                            if(65<score && score<=80 ){
                                score_text= "身体较不平衡，建议马上调理";
                            }
                            if(80<score && score<=90 ){
                                score_text= "身体较为平衡，建议适当调理";
                            }
                            if(90<score && score<=100 ){
                                score_text= "身体较为平衡，建议适当调理";
                            }
                            return score_text;
                        },
                        color: 'auto'
                    },
                    data: [{
                        value: state.info.score,
                    }]
                }]
            };
            option && myChart.setOption(option);
        },

        Send(state,params){
            axios(`/api${sendTemplate}`,{
                ...params.data,
                module_ids:state.module_name,
                template_id:state.template_id
            },'POST')
			.then((data)=>{
                if(data.code==1){
                    //语音
                    audioPaly.play(reportover);
                    state.send = false;
                    Toast.success('发送成功！');
                }else{
                    Notify(data.msg);
                }
            },function(error) {
                if(error.code==401){
                    Notify(error.msg);
                    state.login = true;
                }else{
                    Notify(error.msg);
                }
            });
        },

        //折线图
        Broken(state,params){
            var chartDom = document.getElementById(params.dom);
            echarts.dispose(chartDom);
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                xAxis: {
                  type: 'category',
                  data: params.date,
                  axisTick:{
                    alignWithLabel:true
                  }
                },
                tooltip: {
                    trigger: 'axis'
                },
                yAxis: {
                  type: 'value',
                  max:params.max
                },
                series: [
                  {
                    data: params.data,
                    type: 'line',
                    symbolSize: 10,
                  }
                ]
            };
      
            option && myChart.setOption(option);
        },

        //饼状图
        Cake(state,params){
            var chartDom = document.getElementById(params.dom);
            echarts.dispose(chartDom);
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                color:[
                    '#F16B86',
                    '#F99938',
                    '#FABF38',
                    '#68CBEE',
                    '#6BD890'
                ],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '50%',
                        data: params.data,
                        emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                        }
                    }
                ]
            };
      
            option && myChart.setOption(option);
        },
        
        //请求
        User(state,params){
            axios(`/userapi${userInfo}`,{
                ...params,
                event:1
            }).then((data)=>{
                if(data.code==1){
                    state.user = {
                        nikeName:data.data.nikeName,
                        name:data.data.name,
                        url:data.data.avatarUrl
                    }
                }
            });
        },

        //发送请求
        Axios(state,params){
            state.loading=true;
            let report_api = sessionStorage.token?getReportToken:getReport;
            axios(`/api${report_api}`,params)
			.then((data)=>{
                state.loading=false;
                console.log(data);
                if(data.code==1){
                    //洗数据
                    let big = [] , little = [] ,bigData = [],littleData=[];
                    for( let item in data.data.syndrome_danger ){
                        let hot = 0 , syndrome_danger = data.data.syndrome_danger;
                        for(let i=0,n=syndrome_danger[item].list.length;i<n;i++){
                            if(syndrome_danger[item].list[i].risk_index){
                                hot++;
                                little.push(syndrome_danger[item].list[i].disease_name);
                                data.data.syndrome_danger[item].list[i].key = syndrome_danger[item].name;
                                littleData.push(syndrome_danger[item].list[i]);
                            }
                        }
                        if(hot>0){
                            big.push(syndrome_danger[item].name);
                            bigData.push(syndrome_danger[item]);
                        }
                    }
                    
                    if(data.data.index_list){
                        let bmi=[],date=[],score=[];
                        data.data.index_list.score.map((scores,index)=>{
                            if(scores!==0){
                                let sliceDate = data.data.index_list.date[index].split('-');
                                bmi.push(data.data.index_list.bmi[index]);
                                date.push(`${sliceDate[0]}-${sliceDate[1]}-${sliceDate[2]}`);
                                score.push(data.data.index_list.score[index]);
                            }
                        })
                        data.data.index_list.bmi = bmi.reverse();
                        data.data.index_list.date = date.reverse();
                        data.data.index_list.score = score.reverse();
                    }

                    data.data.bigData = bigData;
                    data.data.big = big;
                    data.data.little = little;
                    data.data.littleData = littleData;

                    let module_list = data.data.module_list;

                    //模块
                    if(module_list){
                        for(let i=0,n=module_list.length;i<n;i++){
                            let description_list = data.data.module_list[i].description_list;
                            let hoo = 0;
                            for(let description in description_list){
                                if(hoo == 0){
                                    module_list[i].description_list[description].active = true;
                                }
                                if(module_list[i].description_list[description].list){
                                    module_list[i].description_list[description].list[0].active = true;
                                }   
                                hoo++;
                            }
                        }
                    }

                    state.info = data.data;
                    state.module = {
                        renovate:true,
                        data:module_list?module_list:[]
                    }

                    let flow = sessionStorage.getItem('flow');
                    if(flow && JSON.parse(flow).flow_info.report_style==2){
                        state.page = 1;
                    }else{
                        state.page = 0;
                    }

                    if(state.page==1||state.page==0){
                        //仪表盘
                        let _this = this;
                        setTimeout(function(){
                            let w = document.body.clientWidth;
                            let h = document.body.clientHeight;
                            console.log(1);
                            if(w>h){
                                _this.dispatch('report/meter','y-main');
                            }else{
                                _this.dispatch('report/meter','main');
                            }
                            if(data.data.index_list){
                                if(w>h){
                                    // _this.dispatch('report/broken',{
                                    //     dom:'y-broken',
                                    //     date:data.data.index_list.date,
                                    //     data:data.data.index_list.score,
                                    //     max:100
                                    // });
                                    _this.dispatch('report/broken',{
                                        dom:'y-weight',
                                        date:data.data.index_list.date,
                                        data:data.data.index_list.bmi,
                                        max:40
                                    });
                                }else{
                                    // _this.dispatch('report/broken',{
                                    //     dom:'broken',
                                    //     date:data.data.index_list.date,
                                    //     data:data.data.index_list.score,
                                    //     max:100
                                    // });
                                    _this.dispatch('report/broken',{
                                        dom:'weight',
                                        date:data.data.index_list.date,
                                        data:data.data.index_list.bmi,
                                        max:40
                                    });
                                }
                                
                            }
                        },1);
                    }
                }else{
                    Toast.fail(data.msg);
                }
            });
        },
        //发送模块
        SendMin(state,params){
            axios(`/api${get_modules}`,params)
            .then((data)=>{
                state.template_id = data.data.template_id;
                state.module_list = data.data.list;
            });
        }
    },
    actions: {              //异步方法
        axiosData({commit},params){
            commit('Axios',params)
        },
        axiosUser({commit},params){
            commit('User',params)
        },
        meter({commit},params){
            commit('Meter',params)
        },
        broken({commit},params){
            commit('Broken',params)
        },
        cake({commit},params){
            commit('Cake',params)
        },
        axiosSend({commit},params){
            commit('Send',params)
        },
        sendMain({commit},params){
            commit('SendMin',params)
        }
    },
    getters: {},
}

export default report;